/**
 * These constants to enable coupling between functions and Angular projects. It is required
 * to have them in a seperate file from their corresponding functions in order to prevent
 * compiling functions/dependencies in the Angular project - i.e: no modules that resolvse to
 * an external module should be imported in this file.
 */

export const constants = {
  validation: {
    password: {
      min: 8,
      max: 64,
    },
    telephoneNumber: {
      min: 10,
      max: 10,
    },
    npi: {
      min: 10,
      max: 10,
    },
    verificationCode: {
      min: 6,
      max: 6,
    },
    // Used for to generate randomized values using the chance library used in tests.
    pools: {
      numeric: '0123456789',
      lower: 'abcdefghijklmnopqrstuvwxyz',
      upper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    },
  },
};
