export type Uid = string;
export type FirebaseAuthToken = string;
export type OktaAccessToken = string;
export type Npi = string;
export type Email = string;
export type Password = string;
export type EmailVerificationCode = string;
export type Name = string;
export type Telephone = string;
export type FactorId = string;
export type Claims = {
  hcpPracticeAdmin?: boolean;
};

interface IReference {
  /**
   * The reason for using the type 'any' for the reference type is becuase AngularFire (Angular Project)
   * and Firebase SDK (Functions Project) have a different implementation of their respective
   * "DocumentReference" types which cannot be cast from one to the other. This should be overwritten in
   * each of the respective projects with the appropriate type for that project.
   */
  ref: any;
}

export type Invitation = Claims & {
  userUid?: Uid;
  email: Email;
  medicalPractice: Pick<MedicalPractice, 'name'> & IReference;
  status?: 'UNREGISTERED' | 'REGISTERED' | 'EMAIL_VERIFIED' | 'COMPLETE';
};

export type MedicalPractice = {
  name: string;
};
