import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../common/services/authentication.service';

@Component({
  selector: 'app-portal',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  hcpPracticeAdmin: boolean = false;
  private isAuthenticated: Observable<boolean> = this.authenticationService.isAuthenticated;
  constructor(public authenticationService: AuthenticationService) {
    this.authenticationService.user.subscribe((user) => {
      this.hcpPracticeAdmin = user?.claims?.hcpPracticeAdmin || false;
    });
  }

  async logout() {
    await this.authenticationService.logout();
  }
}
