import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { InvitationExistsGuard } from './common/guards/invitation-exists.guard';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { InvitationComponent } from './invitations/invitations.component';
import { NavComponent } from './nav/nav.component';
import { OktaCallbackComponent } from './okta-callback/okta-callback.component';
import { ProfileComponent } from './profile/profile.component';
import { RegistrationComponent } from './registration/registration.component';

export const LOGIN_CALLBACK_ROUTE = 'login/callback';
const routes: Routes = [
  {
    path: '',
    component: NavComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },
  {
    path: LOGIN_CALLBACK_ROUTE,
    component: OktaCallbackComponent,
  },
  {
    path: 'invitation/:id',
    component: InvitationComponent,
    canActivate: [InvitationExistsGuard],
  },
  {
    path: 'registration/:id',
    component: RegistrationComponent,
    canActivate: [InvitationExistsGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'error/:type',
    component: ErrorComponent,
  },
  {
    path: '**',
    redirectTo: 'error/not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
