<mat-toolbar color="primary">
  <button mat-icon-button aria-label="HCP Portal Logo">
    <img class="toolbar-logo" src="../../assets/img/logo.svg" alt="Logo" />
  </button>
  <span>HCP Portal</span>
  <span class="example-spacer"></span>
</mat-toolbar>
<p></p>
<div class="outter">
  <div class="inner">
    <div>
      <div><h1 class="mat-headline" color="primary" data-cy="heading">Welcome to the HCP Portal</h1></div>
      <div><h4 class="mat-subheading" color="accent" data-cy="subheading">LivaNova: Health innovation that matters</h4></div>
    </div>
  </div>
</div>

<div class="outter">
  <div class="inner">
    <livn-stepper linear="true" (selectionChange)="onStepChange($event)" #stepper>
      <cdk-step [stepControl]="createPasswordFormGroup" label="Account Setup">
        <ng-template cdkStepLabel>
          <h4 data-cy="body">Thanks for accepting your invitation to join the Portal.</h4>
          <p class="mat-body" data-cy="body">
            Initiate the registration process by creating a new password and accepting our Terms of Use below.
          </p>
        </ng-template>
        <form #createPasswordForm="ngForm" [formGroup]="createPasswordFormGroup">
          <livn-label>Email Addresss</livn-label>
          <livn-hint>The one you use professionally</livn-hint>
          <mat-form-field>
            <input matInput placeholder="Email" formControlName="email" enabled="false" appEmail />
            <mat-error
              *ngIf="
                createPasswordFormGroup.get('email')?.errors &&
                createPasswordFormGroup.get('email')?.errors &&
                createPasswordFormGroup.get('email')?.hasError('required')
              "
            >
              Please enter an email address
            </mat-error>
            <mat-error
              *ngIf="
                createPasswordFormGroup.get('email')?.errors &&
                createPasswordFormGroup.get('email')?.errors &&
                createPasswordFormGroup.get('email')?.hasError('invalid')
              "
            >
              Please enter a valid email
            </mat-error>
          </mat-form-field>
          <br />
          <br />
          <livn-label>Password</livn-label>
          <mat-form-field>
            <input
              matInput
              placeholder="Password"
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              required
              appPassword
            />
            <mat-icon matSuffix (mousedown)="showPassword = true" (mouseup)="showPassword = false">
              {{ showPassword ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            <mat-error>Please provide a valid password</mat-error>
          </mat-form-field>
          <br />
          <h4 class="subtitle left">Terms of Use:</h4>
          <p class="section-text">You must accept the following to continue:</p>
          <p>
            <mat-checkbox formControlName="privicyPolicy" required>
              <a class="checkbox-link" (mousedown)="$event.stopPropagation()" (click)="openPrivacyPolicy($event)"> Privicy Policy: </a>
              This document explains your privacy on this website.
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox formControlName="termsAndConditions" required>
              <a class="checkbox-link" (mousedown)="$event.stopPropagation()" (click)="openTermsAndConditions($event)"
                >Terms & Conditions:</a
              >
              This document explains your rights and obligations on this website.
            </mat-checkbox>
          </p>
          <button
            (click)="submitPasswordStepper($event)"
            mat-flat-button
            color="primary"
            [disabled]="createPasswordForm.invalid || submitPasswordStepperWorking ? 'disabled' : null"
          >
            Continue
          </button>
        </form>
      </cdk-step>
      <cdk-step [stepControl]="submitVerificationFormGroup" label="Verification Code">
        <ng-template cdkStepLabel>
          <h4 data-cy="body">Protecting your data is our top priority.</h4>
          <p class="mat-body" data-cy="body">Please retrieve the verification code from your inbox and submit it below.</p>
        </ng-template>
        <p>
          Confirm your account by entering the<br />
          Verification Code sent to {{ (invitation | async)?.email }}
        </p>
        <form #submitVerificationForm="ngForm" [formGroup]="submitVerificationFormGroup">
          <livn-label>Verification Code</livn-label>
          <livn-hint>Enter your 6-digit code</livn-hint>
          <mat-form-field>
            <input
              matInput
              placeholder="Code"
              formControlName="verificationCode"
              appInputLimiter
              [maxLength]="constants.validation.verificationCode.max"
              [pool]="constants.validation.pools.numeric"
              required
              type="text"
            />
            <mat-error>Please provide a valid code</mat-error>
          </mat-form-field>
          <div>
            <button
              (click)="submitVerificationStepper($event)"
              mat-flat-button
              color="primary"
              [disabled]="submitVerificationForm.invalid ? 'disabled' : null"
            >
              Continue
            </button>
          </div>
        </form>
        <br />
        <h4 class="subtitle">Didn't receive our Email?</h4>
        <p>Please check your spam folder.</p>
        <p>
          If it's not there, we can
          <a [class.disabled]="resendVerificationCodeTimer.count" (click)="tryResendVerificationCode($event)" href>Resend the Email</a>.
        </p>
      </cdk-step>
      <cdk-step [stepControl]="createProfileFormGroup" label="Contact Info & NPI">
        <ng-template cdkStepLabel>
          <h4 data-cy="body">Thank You!</h4>
          <p class="mat-body" data-cy="body">You have verified your account. Please enter your details below.</p>
        </ng-template>
        <form #createProfileForm="ngForm" [formGroup]="createProfileFormGroup">
          <livn-label>First Name</livn-label>
          <mat-form-field>
            <input matInput placeholder="First Name" formControlName="firstName" required />
            <mat-error> First name is required </mat-error>
          </mat-form-field>
          <livn-label>Last Name</livn-label>
          <mat-form-field>
            <input matInput placeholder="Last Name" formControlName="lastName" required />
            <mat-error> Last name is required </mat-error>
          </mat-form-field>
          <livn-label>Office Telephone</livn-label>
          <livn-hint>Office/work number</livn-hint>
          <mat-form-field>
            <input
              matInput
              placeholder="Office telephone"
              formControlName="telephone"
              appInputLimiter
              [maxLength]="constants.validation.telephoneNumber.max"
              [pool]="constants.validation.pools.numeric"
              required
            />
            <mat-error> Please enter a valid 10 digit Telephone Number </mat-error>
          </mat-form-field>
          <livn-label>NPI Number</livn-label>
          <mat-form-field>
            <input
              matInput
              placeholder="NPI Number"
              formControlName="npi"
              appInputLimiter
              [maxLength]="constants.validation.npi.max"
              [pool]="constants.validation.pools.numeric"
              required
            />
            <mat-error> Please enter a valid 10 digit NPI Number </mat-error>
          </mat-form-field>
          <p>
            <mat-checkbox (change)="doNotHaveNpiCheckboxChange($event)" formControlName="doNotHaveNpi">
              <a> I Don't have an NPI</a>
            </mat-checkbox>
          </p>
          <button
            (click)="submitProfileStepper($event)"
            mat-flat-button
            color="primary"
            [disabled]="createProfileForm.invalid || submitProfileStepperWorking ? 'disabled' : null"
          >
            Continue
          </button>
        </form>
      </cdk-step>
      <cdk-step label="Login"> </cdk-step>
    </livn-stepper>
  </div>
</div>
