import { FirebaseAuthToken, OktaAccessToken } from '.';
import { Email, FactorId, Npi, Password, Uid } from './data.model';

export type RequestEmailVerificationCodeRequest = { invitationUid: Uid; email: Email };
export type RequestEmailVerificationCodeResponse = {
  factorId: FactorId;
};

export type SubmitEmailVerificationCodeResponse = void;
export type SubmitEmailVerificationCodeRequest = { email: Email; invitationUid: Uid; userUid: Uid; code: string; factorId: FactorId };

export type RegisterUserRequest = { invitationUid: Uid; email: Email; password: Password };
export type RegisterUserResponse = void;

export type SetProfileRequest = {
  userUid: Uid;
  email: Email;
  invitationUid: Uid;
  npi?: string;
  name: {
    first: string;
    last: string;
  };
  telephone: string;
};

export type SetProfileResponse = void;

export type SetFirestoreNpiRequest = { uid: Uid; npi: Npi };

export type UserExistsRequest = {
  invitationUid: Uid;
  email: Email;
};
export type UserExistsResponse = boolean;

export type ExchangeTokenRequest = {
  token: OktaAccessToken;
};
export type ExchangeTokenResponse = FirebaseAuthToken;
