<div class="outter">
  <div class="inner">
    <div><img class="logo" src=" ../../../../assets/img/logo.svg" alt="Logo" /></div>
    <div>
      <div><h1 class="mat-headline" color="primary" data-cy="heading">Welcome to the HCP Portal</h1></div>
      <div><h4 class="mat-subheading" color="accent" data-cy="subheading">LivaNova: Health innovation that matters</h4></div>
    </div>
    <p class="mat-body" data-cy="body">
      <span data-ja="medical-practice-name">{{ (invitation | async)?.medicalPractice?.name || 'loading...' }}</span> have invited you to the
      LivaNova HCP Portal. <br />
      Please take a few minutes to complete your registration by clicking on the button below.
    </p>
    <button [routerLink]="['/registration', (invitation | async)?.uid || '']" mat-flat-button color="primary">REGISTER FOR ACCESS</button>
  </div>
</div>
