import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../common/services/authentication.service';
import { User } from '../common/services/authentication.service.interface';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userName?: string;
  oktaAccessToken?: string;
  oktaIdToken?: string;
  hcpPracticeAdmin?: boolean;
  user: Observable<User | undefined>;

  constructor(public oktaAuth: OktaAuthService, public authentictionService: AuthenticationService) {
    this.user = this.authentictionService.user;
  }

  async ngOnInit() {
    /**
     * returns an object with user's claims
     */
    const userClaims = await this.oktaAuth.getUser();

    /**
     * user name is exposed directly as property
     */

    this.userName = userClaims.name;
    this.oktaAccessToken = this.oktaAuth.getAccessToken();
    this.oktaIdToken = this.oktaAuth.getIdToken();
  }
}
