/**
 * These constants to enable coupling between functions and Angular projects. It is required
 * to have them in a seperate file from their corresponding functions in order to prevent
 * compiling functions/dependencies in the Angular project - i.e: no modules that resolvse to
 * an external module should be imported in this file.
 */
export const SET_PROFILE = 'setProfile';
export const SUBMIT_EMAIL_VERIFICATION_CODE = 'submitEmailVerificationCode';
export const REQUEST_EMAIL_VERIFICATION_CODE = 'requestEmailVerificationCode';
export const REGISTER_USER = 'registerUser';
export const EXCHANGE_TOKEN = 'exchangeToken';
