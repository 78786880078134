import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LivnHintComponent } from './common/components/livn-hint/livn-hint.component';
import { LivnLabelComponent } from './common/components/livn-label/livn-label.component';
import { LivnStepperComponent } from './common/components/livn-stepper/livn-stepper.component';
import { EmailDirective } from './common/directives/email.directive';
import { InputLimiterDirective } from './common/directives/input-limiter.directive';
import { PasswordDirective } from './common/directives/password.directive';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { InvitationComponent } from './invitations/invitations.component';
import { NavComponent } from './nav/nav.component';
import { oktaConfig } from './okta.config';
import { ProfileComponent } from './profile/profile.component';
import { RegistrationComponent } from './registration/registration.component';

/**
 * Declarations
 */
const directives: (any[] | Type<any>)[] = [PasswordDirective, EmailDirective, InputLimiterDirective];
const customComponents: (any[] | Type<any>)[] = [LivnStepperComponent, LivnHintComponent, LivnLabelComponent];
const components: (any[] | Type<any>)[] = [
  AppComponent,
  HomeComponent,
  InvitationComponent,
  ErrorComponent,
  RegistrationComponent,
  NavComponent,
  ProfileComponent,
];
const declarations = [components, customComponents, directives];
type Module = any[] | Type<any> | ModuleWithProviders<{}>;

/**
 * Imports
 */
const angularModules: Module[] = [CommonModule, BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, CdkStepperModule];
const angularFireModules: Module[] = [AngularFireModule.initializeApp(environment.firebaseConfig), AngularFirePerformanceModule];
const angularMaterialModules: Module[] = [
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatIconModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatStepperModule,
  MatDialogModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatDialogModule,
];
const oktaModules: Module[] = [OktaAuthModule];
const imports = [angularModules, AppRoutingModule, angularFireModules, angularMaterialModules, oktaModules];

/**
 * Providers
 * - Services
 */

const angularFireServices = [PerformanceMonitoringService];
const services = [angularFireServices];

/**
 * - Configuration Objects
 */
const angularMaterialConfigurationObject = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      floatLabel: 'always',
      appearance: 'outline',
    },
  },
  { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
];
const oktaConfigurationObject = [{ provide: OKTA_CONFIG, useValue: oktaConfig }];
const configurationObjects = [angularMaterialConfigurationObject, oktaConfigurationObject];

const providers: Provider[] = [configurationObjects, services];

@NgModule({
  declarations: declarations,
  imports: imports,
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
