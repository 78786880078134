import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { InvitationsService } from './invitations.service';
import { Invitation } from './invitations.service.interface';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
})
export class InvitationComponent implements OnInit, OnDestroy {
  invitation = new BehaviorSubject<Invitation | undefined>(undefined);
  invitationSubscription!: Subscription;
  constructor(private activatedRoute: ActivatedRoute, private invitationsService: InvitationsService) {}

  ngOnInit(): void {
    this.invitationSubscription = this.activatedRoute.params
      .pipe(
        pluck('id'),
        switchMap((id: string) => {
          return this.invitationsService.getInvitation(id);
        })
      )
      .subscribe((i) => {
        this.invitation.next(i);
      });
  }

  ngOnDestroy(): void {
    this.invitationSubscription.unsubscribe();
  }
}
