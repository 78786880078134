<mat-toolbar color="primary">
  <a>HCP Portal</a>
  <span class="nav-spacer"></span>
  <button mat-icon-button aria-label="logout" *ngIf="authenticationService.isAuthenticated | async" (click)="logout()" data-cy="logout">
    Logout
  </button>
</mat-toolbar>

<mat-sidenav-container hasBackdrop="false">
  <mat-sidenav opened mode="push" disableClose="true">
    <mat-nav-list>
      <mat-list-item>
        <mat-icon mat-list-icon>list</mat-icon>
        <a matLine href="/"> link </a>
      </mat-list-item>
      <h3 matSubheader>
        <a [routerLink]="['/profile']">Profile</a>
      </h3>
      <h3 matSubheader>PORTAL ACTIONS</h3>
      <h3 *ngIf="(authenticationService.user | async)?.claims?.hcpPracticeAdmin || false" matSubheader>PORTAL ADMIN</h3>
      <h3 matSubheader>PORTAL HELP</h3>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content> <router-outlet></router-outlet> </mat-sidenav-content>
</mat-sidenav-container>
